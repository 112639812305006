import React, { useState } from "react";
import {
  HeaderWrapper,
  Logo,
  ListWrapper,
  List,
  ListItemWrapper,
  ArrowWrapper,
  ContentWrapper,
  ImagesWrapper,
  MenuWrapper,
  MenuButton,
  MenuLine,
  ListItemMobile,
  ListItemMobileWrapper,
  ListMobileWrapper,
  ListContentWrapper,
  DropDownContainer,
} from "./styles";
import { logoPng } from "../../assets/images";
import { RightArrowSvg } from "../../assets/svgs";
import { HEADER_MENU_ITEMS } from "../../data/constants";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDropdownId, setSelectedDropdownId] = useState("");
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  const openDropDown = (id) => {
    if (selectedDropdownId === id) {
      setIsDropdownOpen(!isDropdownOpen);
    } else {
      setIsDropdownOpen(true);
      setSelectedDropdownId(id);
    }
  };

  return (
    <HeaderWrapper>
      <ContentWrapper>
        <Link to={"/"}>
          <Logo src={logoPng} />
        </Link>
        <MenuWrapper>
          <ListWrapper>
            <ListItemWrapper>
              {HEADER_MENU_ITEMS.map((item) => {
                const { _id, name, showArrow, href, dropdownList } = item;
                const selectedDropdown =
                  selectedDropdownId === _id && isDropdownOpen;
                return (
                  <>
                    <List>
                      <Link to={href}>{name}</Link>
                    </List>
                    {showArrow && (
                      <>
                        <ArrowWrapper
                          onClick={() => openDropDown(_id)}
                          $isActive={selectedDropdown}
                        >
                          <RightArrowSvg
                            style={{
                              transform: selectedDropdown && "rotate(90deg)",
                            }}
                          />
                        </ArrowWrapper>
                        <DropDownContainer $isOpen={selectedDropdown}>
                          {dropdownList?.map((value, idx) => {
                            const { name, href } = value;
                            return (
                              <List key={idx} style={{ fontSize: "14px" }}>
                                <Link to={href}>{name}</Link>
                              </List>
                            );
                          })}
                        </DropDownContainer>
                      </>
                    )}
                  </>
                );
              })}
            </ListItemWrapper>
          </ListWrapper>
          <ImagesWrapper>
            <img
              src="https://www.usb.org/themes/usb_parent_theme/assets/images/icons/red/lock.png"
              alt="Lock Png"
              width={12}
            />
            <img
              src="https://www.usb.org/themes/usb_parent_theme/assets/images/icons/red/search.png"
              alt="Search Png"
              width={16}
            />
          </ImagesWrapper>
        </MenuWrapper>
        <MenuButton onClick={() => setIsOpen(!isOpen)} $isOpen={isOpen}>
          <MenuLine />
          <MenuLine />
          <MenuLine />
        </MenuButton>
      </ContentWrapper>
      <ListMobileWrapper $isOpen={isOpen}>
        <ListItemMobileWrapper>
          {HEADER_MENU_ITEMS.map((item, index) => {
            const { name, showArrow, href } = item;
            return (
              <ListContentWrapper key={index}>
                <ListItemMobile>
                  <Link to={href}>{name}</Link>
                </ListItemMobile>

                <ArrowWrapper
                  style={{ marginRight: "12px" }}
                  $showArrow={!showArrow}
                >
                  {showArrow && <RightArrowSvg />}
                </ArrowWrapper>
              </ListContentWrapper>
            );
          })}
        </ListItemMobileWrapper>
        <ImagesWrapper>
          <img
            src="https://www.usb.org/themes/usb_parent_theme/assets/images/icons/red/lock.png"
            alt="Lock Png"
            width={12}
          />
          <img
            src="https://www.usb.org/themes/usb_parent_theme/assets/images/icons/red/search.png"
            alt="Search Png"
            width={16}
          />
        </ImagesWrapper>
      </ListMobileWrapper>
    </HeaderWrapper>
  );
};

export default Header;
