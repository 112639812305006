import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 86px auto 0;
  padding: 0 1rem;
  margin-bottom: 3.5rem;
  color: #333333;
  ol {
    margin: 20px 0 0 14px;
  }
  ol > li {
    font-size: 0.92rem;
    margin-bottom: 5px;
  }
`;

export const PointWrapper = styled.div``;

export const Title = styled.h1`
  color: #005295;
  font-size: 2.1rem;
  font-weight: 600;
  &::after {
    content: "";
    display: block;
    width: 100px;
    background-color: #8cb4ee;
    height: 6px;
    margin: 35px 0;
  }
`;

export const Desc = styled.p`
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

export const InfoTitle = styled.h2`
  color: #005295;
  font-size: 2rem;
  font-weight: 600;
  margin: 2.5rem 0 1rem;
`;

export const UnderLineLink = styled.a`
  text-decoration: underline;
  color: #005295;
  cursor: pointer;
  &:hover {
    color: #ef3e42;
  }
`;
