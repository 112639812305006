import React from "react";
import {
  Desc,
  DownloadLink,
  FileDownloadContainer,
  FileSize,
  Title,
  UnderLineLink,
  Wrapper,
} from "./styles";
import { FileSvg } from "../../assets/svgs";

const GettingVendorId = () => {
  return (
    <Wrapper>
      <Title>Getting a Vendor ID</Title>
      <Desc>
        If you are a new USB product developer looking to get a vendor ID for
        your company, there are two preferred options for doing this:
        <br />
        <br />
        1. Become a member of the USB-IF. Among the many{" "}
        <UnderLineLink>benefits</UnderLineLink> of being a member is the
        assignment of a vendor ID to your company (if one has not been
        previously assigned). The annual membership fee is US$5,000. Download
        the membership application form below.
      </Desc>
      <FileDownloadContainer>
        <FileSvg />
        <DownloadLink>USB-IF Membership Application Form</DownloadLink>
        <FileSize>115.24 KB</FileSize>
      </FileDownloadContainer>
      <Desc style={{ marginBottom: "12px" }}>
        2. Become a USB-IF non-member logo licensee. Logo licensees are eligible
        to use the USB logo in conjunction with products that pass USB-IF
        compliance testing. In addition, you must also purchase a vendor ID if
        one has not been previously assigned to your company. The licensing fee
        is US$3,500 for a two year term (this fee is waived for USB-IF members).
        Click on the link to download the below documents to become a logo
        licensee.
      </Desc>
      <UnderLineLink>
        Logo Trademark License Agreement and Logo Usage Guidelines
      </UnderLineLink>
      <FileDownloadContainer>
        <FileSvg />
        <DownloadLink>Vendor ID Form</DownloadLink>
        <FileSize>112.14 KB</FileSize>
      </FileDownloadContainer>
      <Desc style={{ marginBottom: "12px" }}>
        If your company does not already have a Vendor ID number, your company
        must execute and return the Vendor ID form along with your USB-IF
        Trademark License Agreement. The Vendor ID is US$6,000. Please keep in
        mind that becoming a USB-IF Logo Licensee alone does not entitle your
        company to USB-IF membership benefits.
      </Desc>
      <Desc>
        If you would like to purchase a vendor ID without signing the logo
        license agreement, the fee for this purchase is US$6,000. If you do not
        execute the logo license agreement, you are not authorized to use the
        USB logo in conjunction with your products regardless of their testing
        status.
      </Desc>
    </Wrapper>
  );
};

export default GettingVendorId;
