import React from "react";
import {
  Title,
  Wrapper,
  PointWrapper,
  InfoTitle,
  Desc,
  UnderLineLink,
} from "./styles";
import { ComplainJpg } from "../../assets/images";

const Compliance = () => {
  return (
    <Wrapper>
      <Title>Compliance</Title>
      <PointWrapper>
        <InfoTitle>USB-IF Compliance Program</InfoTitle>
        <Desc>
          Please review the{" "}
          <UnderLineLink>USB-IF Compliance Updates</UnderLineLink> webpage
          frequently for the latest information regarding the USB-IF Compliance
          Program. The updates may contain information on test requirements
          and/or test procedures.
        </Desc>
        <Desc>
          Subscribe to the USB-IF Compliance Update Mailing List by emailing{" "}
          <UnderLineLink>admin@usb.org</UnderLineLink>.
        </Desc>
        <Desc>
          The Universal Serial Bus (USB) specification defines the product
          design targets at the level of interfaces and mechanisms. To
          complement the specification and enable measurement of compliance in
          real products, the USB-IF has instituted a Compliance Program that
          provides reasonable measures of acceptability. The Compliance Program
          uses multiple test specifications along with a Test ID (TID) to track
          and define the test criteria used to evaluate a product. Products that
          pass this level of acceptability are considered USB-IF certified and
          are added to the Integrator's List and have the right to license the
          USB-IF Logos. The Integrator's list can be found here. For more
          information on the USB-IF Logos please visit the{" "}
          <UnderLineLink>Logo License Information Page</UnderLineLink>.
        </Desc>
        <Desc>
          In addition, there are also a number of whitepapers that provide
          additional background information on testing techniques. These
          documents can help in understanding how to accomplish certification
          requirements, but do not precisely describe the USB-IF Compliance
          Program testing requirements. Whitepapers can be found in the{" "}
          <UnderLineLink>Document Library</UnderLineLink>.
        </Desc>
      </PointWrapper>
      <PointWrapper>
        <InfoTitle>USB-IF Integrators List Marketing Name Guidance</InfoTitle>
        <Desc>
          USB-IF emphasizes the importance and value of consistent messaging on
          USB product packaging, marketing materials, and advertising.
          Inconsistent use of terminology creates confusion in the marketplace,
          can be misleading to consumers, and potentially diminishes USB-IF’s
          trademark rights.
        </Desc>
        <Desc>
          USB-IF strongly discourages its members and the industry at-large from
          using specification names/terminology in consumer-facing branding,
          packaging, advertisements, and other marketing materials.
        </Desc>
        <Desc>
          To support this effort, USB-IF has developed recommendations on how to
          enter marketing names during product certification registration for
          consumer-facing solutions (i.e., not ingredient products such as host,
          device silicon, raw connectors, etc.). To review the recommended
          naming conventions for your product please review the{" "}
          <UnderLineLink>
            USB-IF Integrators List Marketing Name Guidance document
          </UnderLineLink>{" "}
          and choose your product marketing name from the recommended naming
          conventions.
        </Desc>
      </PointWrapper>
      <PointWrapper>
        <InfoTitle>Five Avenues to Certify</InfoTitle>
        <Desc>
          There are five avenues for certifying a product with the USB-IF.
        </Desc>
        <ol>
          <li>USB Compliance Workshops</li>
          <li>USB compliance test development</li>
          <li>www.usb.org Web site</li>
          <li>USB communities at Computex and other events</li>
          <li>
            Marketing programs and collateral materials, such as retail
            newsletters, retail salespeople training, store end-caps, etc.
          </li>
          <li>USB Developer Conferences</li>
          <li>and many more...</li>
        </ol>
        <Desc>
          *NOTE: All companies seeking to use the USB-IF logos on their product
          must have a valid USB-IF Trademark License Agreement on file with the
          USB-IF and the product must be certified.
        </Desc>
      </PointWrapper>
      <PointWrapper>
        <InfoTitle>
          Participate in a USB-IF Sponsored Compliance Workshops
        </InfoTitle>
        <Desc>
          USB-IF Compliance Workshops are members only events held regularly to
          promote USB product development, validate product compliance with the
          USB specification, help foster communication between USB product
          manufacturers and ensure that USB Peripherals operate together. Our
          goal is to eliminate interoperability problems by ensuring proper
          implementation of USB products.
        </Desc>
        <Desc>
          Compliance Workshops are held about once a quarter at hotels in
          various locations and typically run for five days. The USB-IF provides
          special test teams who perform the tests developed for the Compliance
          Program. Private test sessions are also scheduled between System
          vendors and Peripheral vendors. During these test sessions, the
          vendors validate that their products work well together. Once a
          workshop is complete the USB-IF evaluates all test data for
          completeness and compliance. Upon completing the evaluation of all
          attendees the USB-IF notifies each vendor of the result of the
          testing.
        </Desc>
        <Desc>
          For more information about current workshops please visit our{" "}
          <UnderLineLink>Events</UnderLineLink> page.
        </Desc>
      </PointWrapper>
      <PointWrapper>
        <InfoTitle>Attend an Authorized Independent Test Lab</InfoTitle>
        <Desc>
          The USB-IF has a selection of Authorized Independent Test Labs (ITLs)
          located around the world. All test labs do not have the same testing
          ability. Each test lab is authorized by the USB-IF to test a specific
          USB Technology and feature set. For a list of Authorized Independent
          Test Labs and their testing capabilities please visit the link found
          here.
        </Desc>
        <Desc>
          To attend a test lab a vendor must first Register their product with
          the USB-IF. The USB-IF collects a variety of registration data to
          categorize the product and ensure the appropriate testing is
          performed. Once all registration questions are answered and
          appropriate documentation submitted, a vendor will be able to select
          an ITL that is capable of testing the product type categorized by the
          registration information. An email will be sent to you, the ITL and
          USB-IF Administration confirming the selection of ITL and
          registration. Once the Product has been accepted for testing by an ITL
          a Test ID (TID) will be assigned.
        </Desc>
        <Desc>
          At this point it is the vendors and ITLs responsibility to negotiate
          prices and establish a test date. The USB-IF is no longer involved
          until the ITL submits the test data for USB-IF review. Once the USB-IF
          reviews the test data an email will be sent to the vendor stating the
          result of the testing and whether or not the product achieved
          certification.
        </Desc>
        <Desc>
          Please note: Only the lab locations identified are authorized to test
          for USB-IF certification. Satellite lab locations not listed below are
          unauthorized to perform any USB-IF certification testing.
        </Desc>
        <Desc>
          For additional questions regarding the ITL certification process
          please contact <UnderLineLink>admin@usb.org</UnderLineLink>.
        </Desc>
        <InfoTitle style={{ fontSize: "1.2rem" }}>
          To register your product for testing please login and register{" "}
          <UnderLineLink>here</UnderLineLink>.
        </InfoTitle>
      </PointWrapper>
      <PointWrapper>
        <InfoTitle>Participation in the Qualification by Similarity</InfoTitle>
        <Desc>
          When products are very similar, testing of one product may allow the
          other similar products to be added to the Integrator's List without
          re-testing. If any "significant differences" exist between products,
          testing of each is required. The definition of "significant
          differences" is sometimes debatable and the final judgment is the
          responsibility of the compliance review board (CRB), which reports to
          the USB-IF Board of Directors. As decisions are made on what are
          "significant differences," the rules of thumb will be listed here. The
          ultimate responsibility for making sure that various production
          product models do not have "significant differences" from the product
          samples tested lies with each vendor. Audits by USB-IF that reveal
          discrepancies between shipping product and samples tested may be cause
          for required re-test, revocation of certification, and/or legal
          action. Companies' rights to use the USB-IF Logo are covered in the
          <UnderLineLink>Logo License Agreement</UnderLineLink>.
        </Desc>
        <Desc>
          To participate in the Qualification by Similarity program or to read
          more information on the program please visit the link found{" "}
          <UnderLineLink>here</UnderLineLink>.
        </Desc>
      </PointWrapper>
      <PointWrapper>
        <InfoTitle>
          Attend the USB-IF Platform Interoperability Lab(PIL)
        </InfoTitle>
        <Desc>
          When developing compliance programs the USB-IF uses the{" "}
          <UnderLineLink>USB-IF Platform Interoperability lab</UnderLineLink> as
          the initial lab for certification and compliance plan development.
          Products that are early to implement new USB standards are encouraged
          to attend the USB-IF Platform Interoperability Lab for compliance
          testing.
        </Desc>
      </PointWrapper>
      <PointWrapper>
        <InfoTitle>OEM Arrangements</InfoTitle>
        <Desc>
          If your company is purchasing and reselling under your company name
          products currently on the Integrator's List under your supplier’s
          company name, it is possible to have these products added to the
          Integrator's List under your company’s name without retesting. To do
          so, your supplier must submit the online confirmation of product
          identity form for your OEM arrangement. Only the USB-IF primary
          representative from the supplier company may apply. Please remember
          the requirements for a company to display the USB logo in conjunction
          with its products are:
        </Desc>
        <ol>
          <li>
            The products must be listed on the Integrator's List under that
            company's name.
          </li>
          <li>
            The company must execute the{" "}
            <UnderLineLink>USB-IF Trademark License Agreement</UnderLineLink>.
          </li>
        </ol>
        <Desc>
          Both these requirements must be met before your company may use the
          logo in conjunction with your products.
        </Desc>
        <Desc>
          The primary USB-IF representative for the supplier company may
          complete the request form located <UnderLineLink>here</UnderLineLink>.
        </Desc>
        <img
          src={ComplainJpg}
          alt="Complain Page Img"
          style={{ display: "block", maxWidth: "100%", marginTop: "20px" }}
        />
      </PointWrapper>
    </Wrapper>
  );
};

export default Compliance;
