import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 50px;
  max-width: 1200px;
  width: 100%;
  margin: 76px auto 0;
  color: #333333;
  ul {
    margin: 20px 0 20px 30px;
  }
  ul > li {
    font-size: 0.92rem;
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const LeftContainer = styled.div`
  margin-top: 10px;
  padding: 0 1rem;
`;

export const RightContainer = styled.div`
  background: url("https://www.usb.org/themes/usb_parent_theme/assets/images/paper_texture.png");
  box-shadow: 1px 0px 25px rgba(0, 0, 0, 0.3);
  padding: 1rem;
`;

export const Title = styled.h1`
  color: #005295;
  font-size: 2.1rem;
  font-weight: 600;
  &::after {
    content: "";
    display: block;
    width: 100px;
    background-color: #8cb4ee;
    height: 6px;
    margin: 35px 0;
  }
`;

export const Desc = styled.p`
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

export const DownloadDocsTitle = styled.h2`
  color: #005295;
  font-size: 2rem;
  font-weight: 600;
`;

export const FileDownloadContainer = styled.div`
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px 8px;
  margin-bottom: 8px;
  svg {
    width: 16px;
    height: 16px;
    fill: #005295;
  }
`;

export const DownloadLink = styled.a`
  font-size: 0.7rem;
  font-weight: 600;
  flex: 1;
  text-decoration: underline;
  color: #005295;
`;

export const FileSize = styled.div`
  font-size: 0.7rem;
  font-weight: 600;
  color: #005295;
`;

export const UnderLineLink = styled.a`
  text-decoration: underline;
  color: #005295;
  cursor: pointer;
  &:hover {
    color: #ef3e42;
  }
`;

export const RightSidebarContainer = styled.div`
  background-color: #ffffff;
  width: 380px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  padding: 1rem 1rem 2rem;
  margin-bottom: 0.9rem;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;
