import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    transition: transform, color, height, 0.15s ease-out;
    user-select: none;
    -webkit-user-drag: none;
}

a {
    text-decoration: none;
    color: #005295;
}
`;
