import styled from "styled-components";

export const FooterContainer = styled.footer`
  background: url("https://www.usb.org/themes/usb_parent_theme/assets/images/footer-watermark.png")
    bottom left no-repeat #005295;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const NavWrapper = styled.nav`
  display: flex;
  justify-content: center;
  min-height: 400px;
  ul {
    list-style-type: none;
    color: #fff;
    margin: 32px 0 0;
  }
  ul > li {
    margin-bottom: 12px;
    font-size: 14px;
  }
`;

export const ListItemWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  align-items: flex-start;
  gap: 60px;
  max-width: 1200px;
  padding: 45px 0;
  @media only screen and (max-width: 850px) {
    gap: 30px;
    grid-template-columns: repeat(3, auto);
    padding: 15px;
  }
`;

export const LinkTitle = styled.a`
  color: #8cb4ee;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 18px;
`;

export const FooterInfo = styled.section`
  background-color: #003560;
  width: 100%;
  margin: auto;
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
`;

export const FooterContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (max-width: 850px) {
    justify-content: center;
    padding: 15px;
    gap: 20px;
  }
`;

export const PoliciesWrapper = styled.div`
  display: flex;
  gap: 12px;
  hr {
    height: 20px;
  }
`;

export const Info = styled.p`
  color: #8cb4ee;
  font-size: 12px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const PolicyLink = styled.a`
  color: #8cb4ee;
  font-size: 13px;
  cursor: pointer;
`;
