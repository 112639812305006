import styled, {css} from "styled-components";

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  max-width: 1200px;
  width: 100%;
  margin: 95px auto 0;
  padding: 0 1rem;
  margin-bottom: 3.5rem;
  color: #333333;
`;

export const Title = styled.h1`
  color: #005295;
  font-size: 2.1rem;
  font-weight: 600;
  &::after {
    content: "";
    display: block;
    width: 100px;
    background-color: #8cb4ee;
    height: 6px;
    margin: 35px 0;
  }
  ${({ $isCenter }) =>
    $isCenter &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
`;

export const Desc = styled.p`
  font-size: 0.93rem;
  margin-bottom: 1rem;
`;

export const AuthContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  padding: 20px 18px;
  width: 370px;
  display: flex;
  justify-content: flex-end;
  gap: 18px;
  margin-bottom: 12px;
`;

export const AuthButton = styled.button`
  border: 0;
  outline: 0;
  background: #005295;
  color: #fff;
  padding: 8px 14px;
  border-radius: 3px;
`;

export const BottomContainer = styled.div`
  background: url("https://www.usb.org/themes/usb_website_theme/dist/8ada0d42ca02c5c79bcbd3a9e7f28635.png");
  color: #333333;
`;

export const BottomContent = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem 1rem;
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Tabs = styled.div`
  text-align: center;
  background: #fff;
  padding: 1rem;
  width: 30%;
  cursor: pointer;
  &:not(:first-child) {
    box-shadow: 1px 8px 16px rgba(0, 0, 0, 0.2);
  }
  &:first-child {
    color: #fff;
    background-color: #005295;
  }
`;

export const ArticlesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 3.5rem 0 1rem;
`;

export const AnnoucmentWrapper = styled.div`
  background: #fff;
  padding: 1rem;
  border-radius: 3px;
  margin-bottom: 30px;
  width: 48rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
`;

export const AnnouncementLink = styled.a`
  font-size: 15px;
  text-decoration: underline;
  color: #005295;
  &:hover {
    cursor: pointer;
    color: #ef3e42;
  }
`;

export const AnnoucmentDetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const AnnouncementDate = styled.p`
  color: #006ec8;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  margin-top: 8px;
`;

export const AnnouncementSource = styled.span`
  color: #8cb4ee;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  margin-top: 8px;
`;

export const ViewAllButton = styled.a`
  font-size: 15px;
  text-decoration: underline;
  color: #005295;
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    cursor: pointer;
    color: #ef3e42;
  }
  &::after {
    content: "";
    background: url("https://www.usb.org/themes/usb_parent_theme/assets/images/icons/red/arrow_right%402x.png");
    width: 30px;
    height: 20px;
    display: block;
    margin-left: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;
