import styled, { css } from "styled-components";

export const HeaderWrapper = styled.header`
  background-color: #ffffff;
  background-image: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.05) 100%);
  width: 100%;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
`;

export const ContentWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const Logo = styled.img`
  max-width: 110px;
  object-fit: cover;
`;

export const ListWrapper = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
`;

export const List = styled.li`
  font-size: 15px;
  cursor: pointer;
  color: #005295;
  a:hover {
    color: #ef3e42;
  }
  a {
    text-decoration: none;
    color: #005295;
  }
`;

export const ListItemWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const ArrowWrapper = styled.div`
  border: 1px solid #e8f2ff;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #005295;
    border: 1px solid #005295;
    svg {
      fill: #fff;
    }
  }
  ${({ $showArrow }) =>
    $showArrow &&
    css`
      visibility: hidden;
    `}
  ${({ $isActive }) =>
    $isActive &&
    css`
      background: #1372ff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.45);
      border-color: #1372ff;
      svg {
        fill: #fff;
      }
    `}
`;

export const ImagesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  padding: 0 18px;
`;

export const MenuButton = styled.button`
  outline: none;
  padding: 10px 8px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e8f2ff;
  display: flex;
  flex-direction: column;
  gap: 3.6px;
  justify-content: center;
  align-items: center;
  &:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-color: #e8f2ff;
    div {
      background-color: #1372ff;
    }
  }
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      div {
        background-color: #ef3e42 !important;
      }
      div:first-child {
        transform: rotate(47deg);
        translate: 0px 7px;
      }
      div:last-child {
        transform: rotate(314deg);
        translate: 0px -4px;
      }
      div:nth-child(2) {
        opacity: 0;
      }
    `}
`;

export const MenuLine = styled.div`
  width: 22px;
  height: 2.2px;
  border-radius: 4px;
  background: #005295;
`;

export const ListMobileWrapper = styled.nav`
  height: 0;
  overflow: hidden;
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      height: 375px;
    `}
`;

export const ListItemMobileWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-bottom: 12px;
`;

export const ListContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ListItemMobile = styled.li`
  color: #005295;
  flex: 1;
  font-size: 15px;
  padding: 12px 0 12px 18px;
  &:hover {
    background-color: #005295;
    a {
      color: #fff;
    }
  }
  display: flex;
  align-items: center;
`;

export const DropDownContainer = styled.div`
  background: #e8f2ff;
  position: absolute;
  top: 82px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 26px;
  transition: all 0.2s ease-in-out;
  height: 0;
  overflow: hidden;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.20) 0px,
    transparent 17px
  );

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      height: 45px;
      padding: 10px 12px;
      border-bottom: 1px solid #8cb4ee;
    `}
`;
