import React from "react";
import {
  HeroSection,
  Title,
  HeroContent,
  HeroWrapper,
  Desc,
  EventInfoButton,
  MainContainer,
  RowContainer,
  AnnouncementTitle,
  AnnouncementWrapper,
  AnnouncementLink,
  AnnouncementDate,
  RightSidebar,
  QuickLinkImageWrapper,
  QuickLinkContainer,
} from "./styles";
import { memberPng, checkPng, tagPng, filePng } from "../../assets/images";

const Home = () => {
  return (
    <>
      <HeroSection>
        <HeroWrapper>
          <HeroContent>
            <Title>USB-IF Compliance Workshop #134</Title>
            <Desc>
              Registration for the USB-IF Compliance Workshop which will be held
              May 7-10, 2024 is now open! We encourage early registration as
              space is limited.
            </Desc>
            <EventInfoButton>View Event Info</EventInfoButton>
          </HeroContent>
        </HeroWrapper>
      </HeroSection>
      <MainContainer>
        <RowContainer>
          <AnnouncementTitle>Announcements</AnnouncementTitle>
          <AnnouncementWrapper>
            <div>
              <AnnouncementLink>
                USB-IF Announces Publication of New USB4® Specification to
                Enable USB 80Gbps Pe…
              </AnnouncementLink>
              <AnnouncementDate>10/18/2022</AnnouncementDate>
            </div>
            <div>
              <AnnouncementLink>
                USB Promoter Group Announces USB4® Version 2.0
              </AnnouncementLink>
              <AnnouncementDate>09/01/2022</AnnouncementDate>
            </div>
            <div>
              <AnnouncementLink>
                USB-IF Announces New Certified USB Type-C® Cable Power Rating
                Logos
              </AnnouncementLink>
              <AnnouncementDate>09/30/2021</AnnouncementDate>
            </div>
          </AnnouncementWrapper>
          <AnnouncementTitle>Upcoming Events</AnnouncementTitle>
          <div>
            <AnnouncementLink>USB-IF Compliance Workshop #134</AnnouncementLink>
            <AnnouncementDate>05/07/2024</AnnouncementDate>
          </div>
        </RowContainer>
        <RowContainer style={{ alignItems: "flex-end" }}>
          <RightSidebar>
            <AnnouncementTitle>Quick Links</AnnouncementTitle>
            <QuickLinkContainer>
              <AnnouncementLink>Members</AnnouncementLink>
              <QuickLinkImageWrapper>
                <img src={memberPng} alt="" width={43} height={44} />
              </QuickLinkImageWrapper>
            </QuickLinkContainer>
            <QuickLinkContainer>
              <AnnouncementLink>Compliance Program</AnnouncementLink>
              <QuickLinkImageWrapper>
                <img src={checkPng} alt="" width={43} height={44} />
              </QuickLinkImageWrapper>
            </QuickLinkContainer>
            <QuickLinkContainer>
              <AnnouncementLink>EnablingUSB.org</AnnouncementLink>
              <QuickLinkImageWrapper>
                <img src={tagPng} alt="" width={43} height={44} />
              </QuickLinkImageWrapper>
            </QuickLinkContainer>
            <QuickLinkContainer>
              <AnnouncementLink>Legal Agreements</AnnouncementLink>
              <QuickLinkImageWrapper>
                <img src={filePng} alt="" width={31} height={44} />
              </QuickLinkImageWrapper>
            </QuickLinkContainer>
          </RightSidebar>
        </RowContainer>
      </MainContainer>
    </>
  );
};

export default Home;
