import React from "react";
import {
  AuthContainer,
  Desc,
  Title,
  TopContainer,
  BottomContainer,
  BottomContent,
  AuthButton,
  TabsWrapper,
  Tabs,
  AnnouncementLink,
  AnnouncementDate,
  AnnoucmentWrapper,
  AnnouncementSource,
  AnnoucmentDetailWrapper,
  ArticlesWrapper,
  ViewAllButton,
} from "./styles";

const PressRoom = () => {
  return (
    <>
      <TopContainer>
        <div>
          <Title>USB Press Room</Title>
          <Desc>
            Below you will find current USB press releases and articles.
            Additional USB-IF and Member press releases are also available.
            Press releases are in PDF format. If you have trouble downloading
            the files, you can download the free Acrobat Reader.
          </Desc>
        </div>
        <div>
          <AuthContainer>
            <Desc>USB Press Release Updates</Desc>
            <AuthButton>Sign Up</AuthButton>
          </AuthContainer>
          <AuthContainer>
            <Desc>Media Inquiries</Desc>
            <AuthButton>Contact Us</AuthButton>
          </AuthContainer>
        </div>
      </TopContainer>
      <BottomContainer>
        <BottomContent>
          <TabsWrapper>
            <Tabs>USB-IF Press Releases</Tabs>
            <Tabs>Member Press Releases</Tabs>
            <Tabs>Articles</Tabs>
          </TabsWrapper>
          <ArticlesWrapper>
            <Title $isCenter={true}>USB-IF Press Releases</Title>
            <AnnoucmentWrapper>
              <AnnouncementLink>
                USB-IF Announces Publication of New USB4® Specification to
                Enable USB 80Gbps Performance
              </AnnouncementLink>
              <AnnouncementDate>10/18/2022</AnnouncementDate>
            </AnnoucmentWrapper>
            <AnnoucmentWrapper>
              <AnnouncementLink>
                USB Promoter Group Announces USB4® Version 2.0
              </AnnouncementLink>
              <AnnoucmentDetailWrapper>
                <AnnouncementDate>09/01/2022</AnnouncementDate>
                <AnnouncementSource>USB Promoter Group</AnnouncementSource>
              </AnnoucmentDetailWrapper>
            </AnnoucmentWrapper>
            <AnnoucmentWrapper>
              <AnnouncementLink>
                USB-IF Announces New Certified USB Type-C® Cable Power Rating
                Logos
              </AnnouncementLink>
              <AnnoucmentDetailWrapper>
                <AnnouncementDate>09/30/2021</AnnouncementDate>
                <AnnouncementSource>USB Implementers Forum</AnnouncementSource>
              </AnnoucmentDetailWrapper>
            </AnnoucmentWrapper>
            <AnnoucmentWrapper>
              <AnnouncementLink>
                USB Promoter Group Announces USB Power Delivery Specification
                Revision 3.1
              </AnnouncementLink>
              <AnnoucmentDetailWrapper>
                <AnnouncementDate>05/26/2021</AnnouncementDate>
                <AnnouncementSource>USB Promoter Group</AnnouncementSource>
              </AnnoucmentDetailWrapper>
            </AnnoucmentWrapper>
            <ViewAllButton>View All</ViewAllButton>
          </ArticlesWrapper>
        </BottomContent>
      </BottomContainer>
    </>
  );
};

export default PressRoom;
