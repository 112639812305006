import React from "react";
import {
  Wrapper,
  LeftContainer,
  Title,
  Desc,
  DownloadDocsTitle,
  FileDownloadContainer,
  DownloadLink,
  FileSize,
  UnderLineLink,
  RightContainer,
  RightSidebarContainer,
} from "./styles";
import { FileSvg } from "../../assets/svgs";

const About = () => {
  return (
    <Wrapper>
      <LeftContainer>
        <Title>About USB-IF</Title>
        <Desc>
          USB Implementers Forum, Inc. is a non-profit corporation founded by
          the group of companies that developed the Universal Serial Bus
          specification. The USB-IF was formed to provide a support organization
          and forum for the advancement and adoption of Universal Serial Bus
          technology. The Forum facilitates the development of high-quality
          compatible USB peripherals (devices), and promotes the benefits of USB
          and the quality of products that have passed compliance testing. Some
          of the many activities that the USB-IF supports include:
        </Desc>
        <ul>
          <li>USB Compliance Workshops</li>
          <li>USB compliance test development</li>
          <li>www.usb.org Web site</li>
          <li>USB communities at Computex and other events</li>
          <li>
            Marketing programs and collateral materials, such as retail
            newsletters, retail salespeople training, store end-caps, etc.
          </li>
          <li>USB Developer Conferences</li>
          <li>and many more...</li>
        </ul>
        <DownloadDocsTitle>Available Documents for Download</DownloadDocsTitle>
        <FileDownloadContainer>
          <FileSvg />
          <DownloadLink>Articles of Incorporation </DownloadLink>
          <FileSize>103.31 KB</FileSize>
        </FileDownloadContainer>
        <FileDownloadContainer>
          <FileSvg />
          <DownloadLink>Articles of Incorporation </DownloadLink>
          <FileSize>103.31 KB</FileSize>
        </FileDownloadContainer>
        <Desc style={{ marginBottom: "12px" }}>
          If you would like to join the USB-IF,{" "}
          <UnderLineLink>The Membership Agreement Form</UnderLineLink> is also
          available.
        </Desc>
        <Desc>
          These documents are in PDF format and require the{" "}
          <UnderLineLink>Adobe Acrobat Reader</UnderLineLink> for viewing.
        </Desc>
      </LeftContainer>
      <RightContainer>
        <RightSidebarContainer>
          <Title>Board of Directors</Title>
          <Desc>
            The USB-IF, Inc. Board of Directors is composed of the following
            companies and their designated representative Directors:
          </Desc>
          <ul>
            <li>Apple - Dave Conroy</li>
            <li>HP Inc. - Isaac Lagnado</li>
            <li>Intel Corporation - Abdul R. Ismail</li>
            <li>Microsoft Corporation - Matt Chung</li>
            <li>Renesas Electronics - Philip Leung</li>
            <li>STMicroelectronics - Gerard Mas</li>
            <li>Texas Instruments - Anwar Sadat</li>
          </ul>
        </RightSidebarContainer>
        <RightSidebarContainer>
          <Title>Corporate Officers</Title>
          <Desc>The USB-IF, Inc. Corporate Officers are:</Desc>
          <ul>
            <li>President & COO - Jeff Ravencraft</li>
            <li>Chairman - Abdul R. Ismail, Intel Corporation</li>
            <li>Vice-President - Isaac Lagnado, HP Inc.</li>
            <li>CTO - Abdul R. Ismail, Intel Corporation</li>
            <li>Treasurer - Philip Leung, Renesas</li>
            <li>Secretary - Isaac Lagnado, HP Inc.</li>
          </ul>
        </RightSidebarContainer>
        <RightSidebarContainer>
          <Title>Working Committee</Title>
          <Desc>
            The USB-IF, Inc. Board of Directors is composed of the following
            companies and their designated representative Directors:
          </Desc>
        </RightSidebarContainer>
      </RightContainer>
    </Wrapper>
  );
};

export default About;
