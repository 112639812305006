export const HEADER_MENU_ITEMS = [
  {
    _id: Math.random().toString(36).substr(2, 10),
    name: "About USB-IF",
    href: "/about",
    showArrow: true,
    dropdownList: [
      {
        name: "Members",
      },
    ],
  },
  {
    _id: Math.random().toString(36).substr(2, 10),
    name: "Developers",
    href: "/developers",
    showArrow: true,
    dropdownList: [
      {
        name: "Request a Vendor ID",
        href: '/getting-vendor-id'
      },
      {
        name: "Defined Class Codes",
      },
      {
        name: "Human Interface Devices (HID)",
      },
      {
        name: "BOS Descriptor Types",
      },
      {
        name: "Technologies",
      },
      {
        name: "Authentication",
      },
    ],
  },
  {
    _id: Math.random().toString(36).substr(2, 10),
    name: "Compliance",
    href: "/compliance",
    showArrow: true,
    dropdownList: [
      {
        name: "USB4®",
      },
      {
        name: "USB 3.2",
      },
      {
        name: "USB 2.0",
      },
      {
        name: "USB Type-C",
      },
      {
        name: "Cables and Connectors",
      },
      {
        name: "Test Labs",
      },
      {
        name: "USB-IF eStore",
      },
      {
        name: "Request an XID",
      },
    ],
  },
  {
    _id: Math.random().toString(36).substr(2, 10),
    hrf: "/logo-license",
    name: "Logo License Request",
  },
  {
    _id: Math.random().toString(36).substr(2, 10),
    name: "Pressroom",
    href: "/press-room",
    showArrow: true,
    dropdownList: [
      {
        name: "Upcoming Events",
      },
      {
        name: "USB-IF Press Releases",
      },
      {
        name: "Member Press Releases",
      },
      {
        name: "Articles",
      },
    ],
  },
  {
    _id: Math.random().toString(36).substr(2, 10),
    name: "Document Library",
  },
  {
    _id: Math.random().toString(36).substr(2, 10),
    name: "Products",
  },
];

export const FOOTER_LIST_ITEMS = [
  {
    name: "About USB-IF",
    linkItems: ["Members"],
  },
  {
    name: "Compliance",
    linkItems: [
      "USB4®",
      "USB 3.2",
      "USB 2.0",
      "USB Type-C® / USB PD",
      "Cables and Connectors",
      "Compliance Tools",
      "Test Labs",
      "Request an XID",
    ],
  },
  {
    name: "Developers",
    linkItems: [
      "USB4®",
      "USB-IF Logo License",
      "Vendor ID Request",
      "USB Type-C®",
      "USB Charger (USB PD)",
      "USB 3.2",
      "Authentication",
    ],
  },
  {
    name: "Press",
    linkItems: ["USB-IF Press Releases", "Member Press Releases"],
  },
  {
    name: "USB-IF Resources",
    linkItems: ["Document Library", "Product Search"],
  },
  {
    name: "Contact",
    linkItems: ["Members Login"],
  },
];
