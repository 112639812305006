import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Header, Footer } from "./components";
import {
  Home,
  About,
  Developers,
  GettingVendorId,
  Compliance,
  LogoLicense,
  PressRoom,
} from "./pages";
import GlobalStyles from "./styles/global-styles";

const App = () => {
  return (
    <React.Fragment>
      <GlobalStyles />
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/getting-vendor-id" element={<GettingVendorId />} />
          <Route path="/compliance" element={<Compliance />} />
          <Route path="/logo-license" element={<LogoLicense />} />
          <Route path="/press-room" element={<PressRoom />} />
        </Routes>
        <Footer />
      </Router>
    </React.Fragment>
  );
};

export default App;
