import React from "react";
import {
  Desc,
  InfoTitle,
  Title,
  Wrapper,
  BoldDesc,
  UnderLineLink,
  FilesWrapper,
  FileDownloadContainer,
  DownloadLink,
  FileSize,
} from "./styles";
import { FileSvg } from "../../assets/svgs";

const LogoLicense = () => {
  const FileData = [
    {
      fileName: "USB-IF Trademark License Agreement",
      fileSize: "143.6 KB",
    },
    {
      fileName: "Trademark Requirements Chart",
      fileSize: "422.11 KB",
    },
    {
      fileName: "Original Logo Usage Guidelines",
      fileSize: "4.01 MB",
    },
    {
      fileName: "USB Performance Logo Usage Guidelines",
      fileSize: "3.38 MB",
    },
    {
      fileName: "USB Type-C® Cable Logo Usage Guidelines",
      fileSize: "4.93 MB",
    },
    {
      fileName: "USB Data Performance Language Usage Guidelines",
      fileSize: "164.48 KB",
    },
    {
      fileName: "USB Type-C® Language, Product and Packaging Guidelines",
      fileSize: "271.74 KB",
    },
    {
      fileName: "USB-IF Integrators List Marketing Name Guidance",
      fileSize: "146.76 KB",
    },
  ];

  return (
    <Wrapper>
      <Title>USB-IF Logo License</Title>
      <InfoTitle>
        USB-IF Logo Trademark License Agreement and Usage Guidelines
      </InfoTitle>
      <Desc>
        Universal Serial Bus (USB) is a huge success! It's on billions of PCs
        worldwide today, and USB-based peripherals are everywhere. Because of
        its ease of use, speed, and expandability, USB is the preferred PC
        connection for many consumers.
      </Desc>
      <Desc>
        In order to realize this opportunity, USB products must continue to
        enhance the consumers' experience through high quality and ease of use.
        That's why USB Implementers Forum, Inc. introduced trademark-protected
        logos for use with qualified products. To qualify for the right to
        display the certified USB logo in conjunction with a product, the
        product must pass USB-IF compliance testing for product quality.
      </Desc>
      <BoldDesc>
        THE USB-IF LOGOS MAY BE USED ONLY IN CONJUNCTION WITH PRODUCTS WHICH
        HAVE PASSED USB-IF COMPLIANCE TESTING AND ARE CURRENTLY ON THE
        INTEGRATORS LIST. THIS REQUIRES THAT THE COMPANY BE{" "}
        <UnderLineLink>ASSIGNED A USB VENDOR ID NUMBER</UnderLineLink>.
      </BoldDesc>
      <BoldDesc>Reference Documents Available for Download:</BoldDesc>
      <FilesWrapper>
        {FileData.map((item, index) => {
          const { fileName, fileSize } = item;
          return (
            <FileDownloadContainer key={index}>
              <FileSvg />
              <DownloadLink>{fileName}</DownloadLink>
              <FileSize>{fileSize}</FileSize>
            </FileDownloadContainer>
          );
        })}
      </FilesWrapper>
      <BoldDesc>
        The license agreement must be signed by a duly authorized representative
        of your company and sent to the USB Implementers Forum, Inc. in order to
        gain access to the logo artwork files and have the right to use the
        logos on products that pass USB-IF compliance testing. There is a logo
        administration fee of US$3,500 for non-USB-IF members, to be submitted
        with the signed agreement and a <UnderLineLink>Vendor ID</UnderLineLink>{" "}
        form if your company does not already have a VID. The fee is waived for
        USB-IF members. Please note that there are 3 areas of the agreement that
        must be completed. Information is required on the top page 1, in the
        first paragraph of page 1, and on page 8 of the agreement.
      </BoldDesc>
      <BoldDesc>
        Please mail the signed original license agreement with payment, if
        applicable, to the following address:
      </BoldDesc>
      <Desc>
        USB Implementers Forum, Inc.
        <br />
        3855 SW 153rd Drive
        <br />
        Beaverton, OR 97003
        <br />
        USA
      </Desc>
      <BoldDesc>
        If you are submitting payment by check from outside the United States,
        please contact <UnderLineLink>admin@usb.org</UnderLineLink> to ensure we
        are able to accept checks issued by your bank.
      </BoldDesc>
      <Desc>
        The submitter will be notified when the agreement is executed by the
        USB-IF. Subsequently, when a product from your company gets posted to
        the USB-IF Integrators List, the submitter will be notified of your
        company's right to use the appropriate USB-IF logo with that product.
      </Desc>
      <Desc>
        The USB-IF continues to promote the certified USB logos to retail buyers
        and PC OEMs. Don't miss out on the opportunity! Start using the
        certified USB logos on all your USB products now.
      </Desc>
      <Desc>
        If you have any questions about the certified USB logos, send them to{" "}
        <UnderLineLink>admin@usb.org</UnderLineLink>.
      </Desc>
    </Wrapper>
  );
};

export default LogoLicense;
