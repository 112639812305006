import styled from "styled-components";

export const HeroSection = styled.section`
  background: url("https://usb.org/sites/default/files/hero-slider-images/blue-noise.png");
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 76px;
`;

export const Title = styled.h1`
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  color: #ffffff;
  font-size: 1.9rem;
  font-weight: 600;
  @media only screen and (max-width: 700px) {
    font-size: 1.6rem;
  }
`;

export const HeroWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (max-width: 700px) {
    justify-content: center;
    padding: 20px;
  }
`;

export const Desc = styled.p`
  color: #ffffff;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  @media only screen and (max-width: 700px) {
    font-size: 0.8rem;
  }
`;

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  max-width: 590px;
  margin: 30px 0;
`;

export const EventInfoButton = styled.button`
  background: #ef3d42;
  color: #fff;
  padding: 9px 13px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  transition: all 0.15s ease-out;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35);
  font-size: 0.8rem;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #ef3d42;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto 20px;
  @media only screen and (max-width: 850px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;

export const RowContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  &:first-child {
    padding: 0 30px;
  }
`;

export const AnnouncementTitle = styled.h2`
  color: #005295;
  font-size: 32px;
  margin: 35px 0 10px;
`;

export const AnnouncementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const AnnouncementLink = styled.a`
  font-size: 15px;
  text-decoration: underline;
  color: #005295;
  &:hover {
    cursor: pointer;
    color: #ef3e42;
  }
`;

export const AnnouncementDate = styled.p`
  color: #006ec8;
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
`;

export const RightSidebar = styled.div`
  background: url("https://www.usb.org/themes/usb_parent_theme/assets/images/paper_texture.png");
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: 1px 0px 25px rgba(0, 0, 0, 0.3);
  width: 400px;
  @media only screen and (max-width: 850px) {
    align-items: flex-start;
  }
`;

export const QuickLinkImageWrapper = styled.div`
  background: radial-gradient(ellipse at center, #005295 0%, #001a2f 100%);
  margin-left: 1em;
  width: 85px;
  border-radius: 50px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

export const QuickLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;
