import React from "react";
import {
  Title,
  Wrapper,
  InfoTitle,
  Desc,
  FileDownloadContainer,
  DownloadLink,
  FileSize,
  UnderLineLink,
} from "./styles";
import { FileSvg } from "../../assets/svgs";

const Developers = () => {
  return (
    <Wrapper>
      <Title>Information for Developers</Title>
      <InfoTitle>Start using the USB Logo Now!</InfoTitle>
      <Desc>
        <UnderLineLink>Download</UnderLineLink> the USB-IF Trademark License
        Agreement and Usage Guidelines for the USB-IF Logo. The license
        agreement must be signed to access Logo artwork and obtain the right to
        use the Logo with products that pass USB-IF compliance testing.
      </Desc>
      <Desc>
        The agreement necessary for gaining access to the graphics approved for
        linking to the usb.org web site is also{" "}
        <UnderLineLink>available</UnderLineLink>.
      </Desc>
      <InfoTitle>Getting a Vendor ID</InfoTitle>
      <Desc>
        If you are a new USB product developer looking to get a vendor ID for
        your company, there are <UnderLineLink>two options</UnderLineLink> for
        doing this.
      </Desc>
      <InfoTitle>Compliance Program</InfoTitle>
      <Desc>
        The USB specifications define the product design targets at the level of
        interfaces and mechanisms. The USB-IF has instituted a{" "}
        <UnderLineLink>Compliance Program</UnderLineLink> that provides
        reasonable measures of acceptability.
      </Desc>
      <InfoTitle>Document Library</InfoTitle>
      <Desc>
        <UnderLineLink>Access</UnderLineLink> the most current revision of the
        USB specifications, conference presentations, whitepapers, and
        compliance workshop checklists.
      </Desc>
      <InfoTitle>Tools</InfoTitle>
      <Desc>
        <UnderLineLink>Access</UnderLineLink> the most current installation
        utilities. Search for Tools within the Document Library to find the
        latest.
      </Desc>
      <InfoTitle>Valid USB Vendor ID Numbers</InfoTitle>
      <Desc>
        Valid USB Vendor ID Number is a list of companies to which USB-IF has
        assigned each Vendor ID in decimal format. This list is provided as an
        informational resource. The USB Implementers Forum is the authority
        which assigns and maintains all USB Vendor ID Numbers. Each number is
        assigned to one company which has exclusive rights to its use.
        Unauthorized use of assigned or unassigned USB Vendor ID Numbers is
        strictly prohibited. This list is updated quarterly.
      </Desc>
      <FileDownloadContainer>
        <FileSvg />
        <DownloadLink>Valid USB Vendor IDs</DownloadLink>
        <FileSize>616.34 KB</FileSize>
      </FileDownloadContainer>
      <InfoTitle>Invalid VIDs</InfoTitle>
      <Desc>
        The VIDs included on this list have been obsoleted and are not valid.
      </Desc>
      <FileDownloadContainer>
        <FileSvg />
        <DownloadLink>Obsolete VIDs</DownloadLink>
        <FileSize>64.91 KB</FileSize>
      </FileDownloadContainer>
      <InfoTitle>Resources</InfoTitle>
      <Desc>
        <UnderLineLink>xHCI</UnderLineLink> is USB Spec compatible and provides
        an interface to different hardware host controller implementations.
        Multiple implementations of hardware host controllers allow for
        evolution and creativity within the USB Spec. Details of this specs are
        available through this link.
      </Desc>
    </Wrapper>
  );
};

export default Developers;
