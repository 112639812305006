import React from "react";
import {
  FooterContainer,
  NavWrapper,
  ListItemWrapper,
  LinkTitle,
  FooterInfo,
  FooterContentWrapper,
  PoliciesWrapper,
  PolicyLink,
  Info,
} from "./styles";
import { FOOTER_LIST_ITEMS } from "../../data/constants";

const Footer = () => {
  return (
    <FooterContainer>
      <NavWrapper>
        <ListItemWrapper>
          {FOOTER_LIST_ITEMS.map((item, index) => {
            const { name, linkItems } = item;
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <LinkTitle>{name}</LinkTitle>
                <ul>
                  {linkItems.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            );
          })}
        </ListItemWrapper>
      </NavWrapper>
      <FooterInfo>
        <FooterContentWrapper>
          <div>
            <Info>
              Site sponsored by USB Implementers Forum, Inc., creators of USB
              technology.
            </Info>
            <Info>
              USB4®, USB Type-C® and USB-C® are registered trademarks of USB
              Implementers Forum. USB 2.0 Type-C™ is a trademark of USB
              Implementers Forum.
            </Info>
          </div>
          <PoliciesWrapper>
            <PolicyLink>Privacy Statement</PolicyLink>
            <hr />
            <PolicyLink>VTM Group</PolicyLink>
          </PoliciesWrapper>
        </FooterContentWrapper>
      </FooterInfo>
    </FooterContainer>
  );
};

export default Footer;
